const availablePaymentProducts = [
  '3xcb',
  '3xcb-no-fees',
  '4xcb',
  '4xcb-no-fees',
  'alma-3x',
  'alma-4x',
  'bcmc-mobile',
  'bnpp-3xcb',
  'bnpp-4xcb',
  'card',
  'carte-cadeau',
  'credit-long',
  'giropay',
  'ideal',
  'multibanco',
  'mybank',
  'paypal',
  'paysafecard',
  'postfinance-card',
  'postfinance-efinance',
  'przelewy24',
  'sdd',
  'sisal',
  'sofort-uberweisung'
];

export default availablePaymentProducts;
