import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { FormGroup } from '@mui/material';
import {
  getPaymentDraftConfig,
  isMethodEnabled
} from '../../selectors/configSelectors';
import { updatePaymentProductConfig } from '../../actions/sdkConfigActions';

import {
  HiSwitch,
  HiFormControl,
  HiInput
} from '@hipay/design-system/components';

function ConfigHF(props) {
  let handleTextChange = (field, name) => (value) => {
    props.updatePaymentProductConfig('card', field, name, value);
  };

  let handleSwitchChange = (method, field, name) => (event) => {
    props.updatePaymentProductConfig(method, field, name, event.target.checked);
  };

  return (
    <>
      {props.paypalEnabled && props.config.paypal?.merchantPaypalId && (
        <div className="ConfigPayPalV2">
          <h2>
            <FormattedMessage id={'paypal-v2-default-config'} />
          </h2>

          <FormGroup name="groupName">
            <HiSwitch
              id={'paypal.show_bnpl'}
              checked={props.config.paypal.canPayLater}
              onChange={handleSwitchChange('paypal', 'option', 'canPayLater')}
              label={<FormattedMessage id={'show-bnpl'} />}
            />
          </FormGroup>
        </div>
      )}
      {props.cardEnabled && (
        <div className="ConfigHFcard">
          <h2>
            <FormattedMessage id={'card-default-config'} />
          </h2>

          <FormGroup name="groupName">
            <HiSwitch
              id={'global.multi_use'}
              checked={props.config.card.multi_use}
              onChange={handleSwitchChange('card', 'option', 'multi_use')}
              label={<FormattedMessage id={'multi-use'} />}
            />
          </FormGroup>

          <div className="block-container">
            <div className="block">
              <h3>
                <FormattedMessage id={'cardHolder'} />
              </h3>

              <FormGroup name="groupName">
                <HiSwitch
                  id={'cardHolder.helpButton'}
                  checked={props.config.card.fields.cardHolder.helpButton}
                  onChange={handleSwitchChange(
                    'card',
                    'cardHolder',
                    'helpButton'
                  )}
                  label={<FormattedMessage id={'helpButton'} />}
                />
                <HiSwitch
                  id={'cardHolder.uppercase'}
                  checked={props.config.card.fields.cardHolder.uppercase}
                  onChange={handleSwitchChange(
                    'card',
                    'cardHolder',
                    'uppercase'
                  )}
                  label={<FormattedMessage id={'force-uppercase'} />}
                />
              </FormGroup>

              <HiFormControl
                className="textField"
                id="defaultFirstname"
                name="defaultFirstname"
                label={props.intl.formatMessage({ id: 'defaultFirstname' })}
              >
                <HiInput
                  value={
                    props.config.card.fields.cardHolder.defaultFirstname ?? ''
                  }
                  onChange={handleTextChange('cardHolder', 'defaultFirstname')}
                  placeholder="John"
                />
              </HiFormControl>

              <HiFormControl
                className="textField"
                id="cardHolder.defaultLastname"
                name="cardHolder.defaultLastname"
                label={props.intl.formatMessage({ id: 'defaultLastname' })}
              >
                <HiInput
                  value={
                    props.config.card.fields.cardHolder.defaultLastname ?? ''
                  }
                  onChange={handleTextChange('cardHolder', 'defaultLastname')}
                  placeholder="Doe"
                />
              </HiFormControl>

              <HiFormControl
                className="textField"
                id="cardHolder.placeholder"
                name="cardHolder.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.cardHolder.placeholder ?? ''}
                  onChange={handleTextChange('cardHolder', 'placeholder')}
                  placeholder="John Doe"
                />
              </HiFormControl>
            </div>

            <div className="block">
              <h3>
                <FormattedMessage id={'cardNumber'} />
              </h3>
              <FormGroup name="groupName">
                <HiSwitch
                  id={'cardNumber.helpButton'}
                  checked={props.config.card.fields.cardNumber.helpButton}
                  onChange={handleSwitchChange(
                    'card',
                    'cardNumber',
                    'helpButton'
                  )}
                  label={<FormattedMessage id={'helpButton'} />}
                />
                <HiSwitch
                  id={'cardNumber.hideCardTypeLogo'}
                  checked={props.config.card.fields.cardNumber.hideCardTypeLogo}
                  onChange={handleSwitchChange(
                    'card',
                    'cardNumber',
                    'hideCardTypeLogo'
                  )}
                  label={<FormattedMessage id={'hideCardTypeLogo'} />}
                />
              </FormGroup>
              <HiFormControl
                className="textField"
                id="cardNumber.placeholder"
                name="cardNumber.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.cardNumber.placeholder ?? ''}
                  onChange={handleTextChange('cardNumber', 'placeholder')}
                  placeholder="Placeholder"
                />
              </HiFormControl>
            </div>

            <div className="block">
              <h3>
                <FormattedMessage id={'expiryDate'} />
              </h3>

              <HiFormControl
                className="textField"
                id="expiryDate.placeholder"
                name="expiryDate.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.expiryDate.placeholder ?? ''}
                  onChange={handleTextChange('expiryDate', 'placeholder')}
                  placeholder="Placeholder"
                />
              </HiFormControl>

              <FormGroup name="groupName">
                <HiSwitch
                  id={'expiryDate.helpButton'}
                  checked={props.config.card.fields.expiryDate.helpButton}
                  onChange={handleSwitchChange(
                    'card',
                    'expiryDate',
                    'helpButton'
                  )}
                  label={<FormattedMessage id={'helpButton'} />}
                />
              </FormGroup>
            </div>
            <div className="block">
              <h3>
                <FormattedMessage id={'cvc'} />
              </h3>

              <HiFormControl
                className="textField"
                id="cvc.placeholder"
                name="cvc.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.cvc.placeholder ?? ''}
                  onChange={handleTextChange('cvc', 'placeholder')}
                  placeholder="Placeholder"
                />
              </HiFormControl>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    config: getPaymentDraftConfig(state),
    cardEnabled: isMethodEnabled(state, 'card'),
    paypalEnabled: isMethodEnabled(state, 'paypal')
  };
};

export default connect(mapStateToProps, {
  updatePaymentProductConfig
})(injectIntl(ConfigHF));
