export const getCurrency = (state) => {
  return state.sdkConfigReducer.currency;
};

export const getPaymentModeConfig = (state) => {
  return state.sdkConfigReducer.config.payment_mode;
};

export const getPaymentModeDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.payment_mode;
};

export const getFrontendSdkConfig = (state) => {
  return state.sdkConfigReducer.config.sdk_frontend;
};

export const getFrontendSdkDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.sdk_frontend;
};

export const getBackendSdkConfig = (state) => {
  return state.sdkConfigReducer.config.sdk_backend;
};

export const getBackendSdkDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.sdk_backend;
};

export const getClientConfig = (state) => {
  // Get client config
  let returnedClient = { ...state.sdkConfigReducer.config.client };

  // If env = stage && public credentials not filled, use default credentials
  if (
    returnedClient.environment === 'stage' &&
    (!returnedClient.username || !returnedClient.password)
  ) {
    returnedClient.username =
      process.env.REACT_APP_TEST_ACCOUNT_PUBLIC_USERNAME;
    returnedClient.password =
      process.env.REACT_APP_TEST_ACCOUNT_PUBLIC_PASSWORD;
  }

  return returnedClient;
};

export const getClientDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.client;
};

export const getCarouselConfig = (state) => {
  return state.sdkConfigReducer.config.payment.carousel;
};

export const getCarouselDraftConfig = (state) => {
  let returnedState = {
    ...state.sdkConfigReducer.draftConfig.payment.carousel
  };

  if (!returnedState.hasOwnProperty('payment_product')) {
    returnedState.payment_product = [];
  }

  return returnedState;
};

export const getFullPaymentConfig = (state) => {
  return { ...state.sdkConfigReducer.config.payment };
};

export const getPaymentConfig = (state) => {
  // Get payment product
  let paymentProduct =
    state.sdkConfigReducer.config.payment_mode.mode === 'HP'
      ? 'hosted-payments'
      : state.sdkConfigReducer.config.payment_mode.method;

  let paymentConfig;

  // If Hosted Payments, return full config
  if (paymentProduct === 'hosted-payments') {
    paymentConfig = { ...state.sdkConfigReducer.config.payment };
  }
  // Else return payment product config
  else {
    paymentConfig = {
      ...state.sdkConfigReducer.config.payment[`${paymentProduct}`]
    };
  }

  return paymentConfig;
};

export const getPaymentDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.payment;
};

export const getStylesConfig = (state) => {
  return state.sdkConfigReducer.config.styles;
};

export const getStylesDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.styles;
};

export const getApplePayStylesDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.payment.paymentRequestButton
    .applePayStyle;
};

export const getPaypalStylesDraftConfig = (state) => {
  return state.sdkConfigReducer.draftConfig.payment.paypal.paypalButtonStyle;
};

export const isHPageIFrame = (state) => {
  return state.sdkConfigReducer.iframe;
};

export const getThemeCode = (state) => {
  return state.sdkConfigReducer.theme_code;
};

export const getCurrentPaymentData = (state) => {
  return state.sdkConfigReducer.draftConfig.hpageCurrentPaymentData;
};

export const isSdkSettingsValid = (state) => {
  // CLIENT
  const client = { ...state.sdkConfigReducer.draftConfig.client };
  // BACKEND SDK
  const sdk_backend = { ...state.sdkConfigReducer.draftConfig.sdk_backend };

  // Has error on SDK JS loading
  const hasSdkFrontendError = state.appReducer.hasSdkFrontendError;

  if (hasSdkFrontendError) {
    return false;
  }

  // If env = production
  if (client.environment === 'production') {
    // If missing one credentials
    if (
      !client.username.length ||
      !client.password.length ||
      !sdk_backend.username.length ||
      !sdk_backend.password.length
    ) {
      return false;
    }
  } else {
    // If missing only one credentials filled
    if (
      !(
        !client.username.length &&
        !client.password.length &&
        !sdk_backend.username.length &&
        !sdk_backend.password.length
      ) &&
      !(
        client.username.length &&
        client.password.length &&
        sdk_backend.username.length &&
        sdk_backend.password.length
      )
    ) {
      return false;
    }
  }

  return true;
};

export const isMethodEnabled = (state, method) => {
  return (
    (state.sdkConfigReducer.draftConfig.payment_mode.mode === 'HF' &&
      state.sdkConfigReducer.draftConfig.payment_mode.method === method) ||
    (state.sdkConfigReducer.draftConfig.payment_mode.mode !== 'HF' &&
      (!state.sdkConfigReducer.draftConfig.payment?.carousel?.payment_product
        ?.length ||
        state.sdkConfigReducer.draftConfig.payment.carousel.payment_product.includes(
          method
        )))
  );
};
